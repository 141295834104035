<template>
  <Vacations :page="'view'" />
</template>

<script>
import Vacations from '../../../Shared/Pages/Vacations/index.vue'

export default {
  components: {
    Vacations
  }
}
</script>
